<script>
import {mapGetters} from "vuex";

export default {
  name: "PlayerHands",
  components: {
    PlayerCardFront: () => import(/* webpackChunkName: "player-card-front-v2" */ '@/components/Game/PlayerCardFront.vue'),
    PlayerCardBack: () => import(/* webpackChunkName: "player-card-back-v2" */ '@/components/Game/PlayerCardBack.vue'),
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    me: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      holeCard: {
        first: false,
        second: false,
      },
    }
  },
  computed: {
    ...mapGetters('game', ['roundKey', 'isDeal', 'isPlaying', 'isGameOver', 'currentRoomState']),
    isBettingRound() {
      return !this.isGameOver && this.currentRoomState >= this.roomState.Deal && this.currentRoomState <= this.roomState.ShowResult;
    },
    isWaiting() {
      return this.player.reserveState === this.playState.WAIT_FROM_ENTER
          || this.player.reserveState === this.playState.WAIT_FROM_BIG
          || this.player.reserveState === this.playState.WAIT_FROM_SMALL
          || this.currentRoomState === this.roomState.BreakTime
          || this.currentRoomState === this.roomState.WaitOtherPlayer;
    }
  },
  // watch: {
  //   isBettingRound: {
  //     immediate: true,
  //     handler() {
  //       this.$nextTick(() => {
  //         this.holeCard.first = !!this.isBettingRound;
  //         this.holeCard.second = !!this.isBettingRound;
  //       })
  //     }
  //   }
  // },
  methods: {
    onGetHoleCard({phase, playerIndex}) {
      if (this.player.idx !== playerIndex) {
        return;
      }

      if (phase === 0) {
        this.holeCard.first = true;
      } else if (phase === 1) {
        this.holeCard.second = true;
      }
    },
  },
  created() {
    this.EventBus.$on('card-dist-start', () => {
      // this.$store.commit('game/SET_DEALING_CARDS_VISIBLE', true);
      this.holeCard.first = false;
      this.holeCard.second = false;
    });
    this.EventBus.$on('card-dist-complete', this.onGetHoleCard);
  },
  mounted() {
    // this.$nextTick(() => {
    this.holeCard.first = !!this.isBettingRound;
    this.holeCard.second = !!this.isBettingRound;
    // });
  }
}
</script>

<template>
  <div class="area_card" v-if="isPlaying && !isWaiting">
    <template v-if="me">
      <PlayerCardFront order="first" :visible="holeCard.first"/>
      <PlayerCardFront order="second" :visible="holeCard.second"/>
    </template>
    <template v-else>
      <PlayerCardBack order="first" :visible="holeCard.first" :me="me" :player="player"/>
      <PlayerCardBack order="second" :visible="holeCard.second" :me="me" :player="player"/>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>